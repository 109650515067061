<template>
  <transition name="fade">
    <div
      class="modal"
      :id="id"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="modalsize"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              v-if="closebtn"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="icofont icofont-close"
              />
            </button>
            <h5
              class="modal-title"
              :id="id+'-title'"
            >
              {{ title }}
            </h5>
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div
            class="modal-footer"
            v-if="footer"
          >
            <slot name="footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import jQuery from 'jquery'
import { mapGetters } from 'vuex'
const $ = jQuery
export default {
  name: 'Modal',
  props: {
    titleStyle: { type: String, require: true },
    title: { type: String, require: true },
    closebtn: { type: Boolean, default: true },
    footer: { type: Boolean, default: true },
    size: { type: String, default: 'medium' },
    id: { type: String, default: 'myModal' }
  },
  watch: {
    done () {
      $('#' + this.id).modal('hide')
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['done']),
    modalsize () {
      if (this.size === 'small') { return 'modal-sm' }
      if (this.size === 'large') { return 'modal-lg' }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .modal{
    .modal-body, .modal-title{
      text-align: left;
    }
    .modal-header{
      position: relative;
      text-align: left;
      .close{
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
</style>
