<template>
    <div>
        <h4>Firmware</h4>
        <modal id="addFirmware" title="Ajouter une version logiciel">
            <form id="add_firmware" class="form-horizontal">
                <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="model">Modèle</label>
                    <select class="form-control" v-select="{placeholder: 'Selectionnez le modèle'}" name="model" id="model" v-model="model">
                        <option/>
                        <option v-for="(m, index) in models" :key="index" :value="m.uid">{{m.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="version">Version Materiel</label>
                    <select class="form-control" v-select="{placeholder: 'Selectionnez la version'}" name="version" id="version" v-model="version">
                        <option/>
                        <option v-for="(v, index) in availableVersions" :key="index" :value="v.uid">{{v.name}} [{{v.libelle}}]</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="nom">Tag Logiciel</label>
                    <input type="text" class="form-control" name="nom" id="nom" v-model="libelle"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Description</label>
                    <textarea
                      name="description"
                      id="description"
                      class="form-control"
                      rows="4"
                      v-model="description"
                    />
                  </div>
                </div>
              </div>
            </form>
            <template slot="footer">
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="addingFirmware"
              > Ajouter</a>
            </template>
          </modal>
        <data-table :dataSource="firmwares" :headers="headers" table-class="table-striped table-hover">
            <template slot="actions">
              <a href="javascript:void(0)"
                class="btn btn-outline-primary btn-icon"
                @click.prevent="showAddModal"
              >
                <i class="icofont icofont-plus" />
              </a>
            </template>
        </data-table>
    </div>
</template>
<script>
import jQuery from 'jquery'
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import Modal from '../../components/modal.vue'
import { COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
import {ADD_FIRMWARE} from '../../graphql/host'
const $ = jQuery
const Version = () => import('../../components/host/firmware/version.vue')
const Model = () => import('../../components/host/firmware/model.vue')
export default {
    components: { DataTable, Modal},
    data() {
      return {
          libelle: '',
          description: '', 
          model: null,
          version: null
      }  
    },
    watch: {
        model(){
            this.version = null
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        showAddModal(){
            $('#addFirmware').modal('show')
        },
        addingFirmware(){
            this.$apollo.mutate({
                mutation: ADD_FIRMWARE,
                variables: {
                    firmware: {
                        "version": this.version,
                        "description": this.description,
                        "libelle": this.libelle
                    }
                  },
                update: () => {
                    this.model = null
                    this.libelle = null
                    this.description = null
                    this.done()
				},
            })
        }
    },
    computed: {
        ...mapGetters({
            firmwares: 'host/firmwares',
            models: 'host/models',
            versions: 'host/versions'
        }),
        availableVersions(){
            if(this.model === null) return []
            return [...this.versions].filter(item => item.model === this.model)
        },
        headers(){
            return [
                {label: 'Nom', name: 'libelle', type: TEXT_TYPE, sortable: true},
                {label: 'Modèle', name: 'model', type: COMPONENT_TYPE, component: Model},
                {label: 'Version', name: 'version', type: COMPONENT_TYPE, component: Version},
                {label: 'Recommandé', name: 'recommanded', type: TEXT_TYPE, sortable: true},
                {label: 'Obselète', name: 'deprecat', type: TEXT_TYPE, sortable: true},
                {label: 'Date Invalide', name: 'dateInvalid', type: DATE_TYPE, sortable: true},
                // {label: 'Action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>
